import React from "react"
import Layout from "../components/layout"
// import SEO from "../components/seo"

const Resources = () => {
  return (
    <Layout>
      <div style={{marginTop:"2em auto",textAlign:"center"}}>Coming soon. . .</div>
    </Layout>
  )
}

export default Resources
